import {BasicsClass} from "@/common/BasicsClass";

export default class GroupBuyingAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['list', {
            url: '/open/classify/lists',
            name: 'list',
            label: '列表'
        }],
        ['add', {
            url: '/open/classify/add',
            name: 'add',
            label: '添加'
        }],
        ['edit', {
            url: '/open/classify/edit',
            name: 'edit',
            label: '编辑'
        }],
        ['delete', {
            url: '/open/classify/delete',
            name: 'delete',
            label: '删除'
        }],
    ])


    public classifylists(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('list').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public classifyadd(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('add').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public classifyedit(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('edit').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }



    public classifydelete(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('delete').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

}
